<template>
  <v-dialog
    v-model="isDeleteModalOpen"
    persistent
    max-width="450px"
  >
    <v-card>
      <v-card-title class="text-h3 font-weight-medium">
        {{ $t("stockAdjustments.cancel_adjustment") }}</v-card-title
      >
      <v-card-text>
          <p class="text-left">
            {{ $t("stockAdjustments.cancel_adjustment_text") }}
          </p>
          <v-form>
            <v-textarea
              outlined
              label="Reason for cancellation"
              v-model="note"
              rows="3"
            ></v-textarea>
          </v-form>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="close()"
          color="indigo"
          text
        >
          {{ $t("stockAdjustments.no_cancel_adjustment") }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="confirm()"
          text
        >
          {{ $t("stockAdjustments.yes_cancel_adjustment") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isDeleteModalOpen: Boolean,
    toggleDeleteModal: Function,
    stockAdjustment: Object,
    return_to_root: String
  },

  data() {
    return { 
      isLoading: false,
      note:"",
      stock_adjustment_status: 'canceled'};
  },
  methods: {
    close() {
      this.toggleDeleteModal();
    },
    async confirm() {
      try {
        this.isLoading = true;
        this.stockAdjustment.stock_adjustment_status = this.stock_adjustment_status;
        this.stockAdjustment.canceled_note = this.note;
        await this.$store.dispatch(
          "stockAdjustments/update_status",
          this.stockAdjustment
        );
        
        this.toggleDeleteModal();
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
        this.isLoading = false;

      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>